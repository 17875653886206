.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}

.custom-grid-padding {
  --ion-grid-padding-xs: 2px;
  --ion-grid-padding-sm: 2px;
  --ion-grid-padding-md: 2px;
  --ion-grid-padding-lg: 2px;
  --ion-grid-padding-xl: 2px;
}

.custom-column-padding {
  --ion-grid-column-padding-xs: 2px;
  --ion-grid-column-padding-sm: 2px;
  --ion-grid-column-padding-md: 2px;
  --ion-grid-column-padding-lg: 2px;
  --ion-grid-column-padding-xl: 2px;
  padding: 5px;
}

.img-size {
  width: 200px;
  height: 200px;
}
