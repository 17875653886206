.custom-class {
  --height: 260;
  --width: 340;
  --max-width: 340;
}

.custom-modal {
  --width: 400;
  --height: 400;
}
