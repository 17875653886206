* {
  margin: 0;
  padding: 0;
}

.visible {
  color: black;
}

.display_none {
  display: none;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.pull_down_small {
  width: 20px;
  margin-left: 8px;
}

.text-center {
  text-align: center !important;
}

.button {
  text-align: center;
}

.margin-top-small {
  margin-top: 8px;
}

.margin-bottom-small {
  margin-bottom: 8px;
}

.margin-bottom-large {
  margin-bottom: 16px;
}

.margin-auto {
  margin: 0 auto;
}

.calendar-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 5%;
  height: 80vh !important;
}

.modalbodywrapper {
  height: auto;
  width: 30%;
  min-width: 305px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2% 2.5%;
  background-color: #fff;
}

.icon-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.pull-down {
  width: 100px;
}

.rbc-event-label {
  display: none;
}
.rbc-event-content {
  font-size: 16px;
}

.white {
  background-color: white !important;
}

.irotuki {
  background-color: #dcedc8 !important;
}

.rbc-events-container {
  margin-right: 0px !important;
}

.rbc-time-column:first-of-type > .rbc-timeslot-group > .rbc-time-slot {
  background-color: white !important;
}

@media (max-width: 1024px) {
  .rbc-event-label {
    display: none;
  }

  .rbc-event-content {
    font-size: 10px;
  }

  .rbc-events-container {
    margin-right: 0px !important;
  }
}
