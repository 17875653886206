.flex {
  display: flex;
}

.flex_center {
  justify-content: center;
}

.table {
  min-width: 600px;
}

.tableCell_noraml {
  width: 130px;
  vertical-align: inherit !important;
}

.tableCell_large {
  width: 130px;
  vertical-align: inherit !important;
}

.tableCell_minWidth {
  min-width: 183px;
}

.tableCell_fabButton {
  height: auto !important;
  margin-left: 12px !important;
  background-color: #3880ff !important;
}
.tableCell_fabButtonRed {
  height: auto !important;
  margin-left: 12px !important;
  background-color: #e60e0e !important;
  color: #ffffff !important;
}
.tableCell_disabled_fabButton {
  height: auto !important;
  margin-left: 12px !important;
  background-color: #e0e0e0 !important;
}

.tableCell_fabButton > span {
  color: #ffffff;
}

.add_height_for_tableCell {
  height: 56px;
}

.tableFooter_wapper {
  position: relative;
}

.table_Footer_Style {
  position: absolute;
}
